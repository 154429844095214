define("otcns-career-pathways/metadata", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {

		regions: {
			"NS": "Nova Scotia",
			"NB": "New Brunswick",
			"NL": "Newfoundland & Labrador",
			"PE": "Prince Edward Island",
			"QC": "Quebec",
			"ON": "Ontario",
			"MB": "Manitoba",
			"SK": "Saskatchewan",
			"AB": "Alberta",
			"BC": "British Columbia",
			"YT": "Yukon",
			"NT": "Northwest Territories",
			"NU": "Nunavut"
		}
	};
});