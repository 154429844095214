define('otcns-career-pathways/pods/application/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		load: Ember.inject.service(),
		navigator: Ember.inject.service(),

		beforeModel: function beforeModel() {
			console.log('beforemodel');
			// instruct the application to load the bundled database for the first time (if not already loaded)
			return Ember.RSVP.all([this.get('load').setup()]);
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			if (!("cordova" in window)) {
				controller.set("isBrowser", true);
			}
		},


		actions: {
			loading: function loading(transition, originRoute) {
				console.log("Loading");
				/*
      	let controller = this.controllerFor('application');
    controller.set('currentlyLoading', true);
      	*/
				transition.promise.finally(function () {
					console.log("Loaded");
					//    controller.set('currentlyLoading', false);
				});
			},
			willTransition: function willTransition(transition) {

				this.controller.set("lastRoute", this.get('router.url'));
				this.controller.get("transitionHistory").push(transition);
			},
			back: function back() {

				var transitionHistory = this.controller.get("transitionHistory");
				// if history is too shallow, just go back home.
				if (transitionHistory.length < 2) this.transitionTo("careers.index");

				var currentTransition = transitionHistory.pop();
				var lastTransition = transitionHistory.pop();

				lastTransition.retry();
			},
			dismissFavourites: function dismissFavourites() {

				var destination = this.controller.get("returnTo") || "careers.index";
				this.controller.set("returnTo", false);
				this.controller.set('inFavourites', false);

				// scan to see if there's a 'favourites' transition in the stack
				// if so, rewind to that point, minus one
				var transitionHistory = this.controller.get("transitionHistory");
				while (transitionHistory.length > 1) {
					var current = transitionHistory.pop();
					if (current.targetName == "favourites") ;
					break;
				}
				transitionHistory.pop();

				this.transitionTo(destination);
			},
			toggleFavourite: function toggleFavourite(id) {
				// check to see whether the id is even in the favourites
				// if it is, remove, and then confirm

				var self = this;
				var animation = "bounce";

				this.get("store").query('favourite', { filter: { doc_id: id } }).then(function (results) {
					console.log(results.get("length"));
					if (results.get("length") > 0) {
						console.log("deleting");

						animation = 'shake';
						results.forEach(function (item) {
							item.destroyRecord();
						});
						Ember.$(".navbar-link.navbar-right .star").addClass(animation + " animated").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
							// trick to execute the animation again
							$(e.target).removeClass(animation + ' animated');
						});
					} else {

						var fav = self.store.createRecord('favourite', {
							doc_id: id
						});

						fav.save().then(function (newDoc) {

							Ember.$(".navbar-link.navbar-right .star").addClass(animation + " animated").one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
								// trick to execute the animation again
								$(e.target).removeClass(animation + ' animated');
							});
						});
					}
				}).catch(function (err) {
					console.log(err);
				});

				/*
    			if(this.get("store").hasRecordForId('favourite', "favourite_" + id ) ){
    
    				animation = 'shake';
    				self.store.findRecord('favourite', "favourite_" + id, { backgroundReload: false }).then(function(doc){
    					doc.destroyRecord();
    					Ember.$(".navbar-link.navbar-right .star")
    						.addClass(animation + " animated")
    						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(e){
    							// trick to execute the animation again
    							$(e.target).removeClass(animation + ' animated'); 
    						});
    				});
    
    			}
    			else{
    
    				//console.log("this item hasn't been favourited yet");
    				var fav = self.store.createRecord('favourite', {
    					id: "favourite_" + id,
    					doc_id: id,
    				});
    
    				fav.save().then(function(newDoc){
    
    					Ember.$(".navbar-link.navbar-right .star")
    						.addClass(animation + " animated")
    						.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(e){
    							// trick to execute the animation again
    							$(e.target).removeClass(animation + ' animated'); 
    						});
    				})
    
    			}
    */
			}
		}

	});
});