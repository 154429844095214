define("otcns-career-pathways/mixins/remember-scroll", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var isDesktop = window.matchMedia("only screen and (min-width: 414px)").matches;

			if (!isDesktop) {
				Ember.$("BODY").css("minHeight", Ember.$(".content-wrapper")[0].scrollHeight);
				Ember.$("BODY").scrollTop(this.get('lastScroll') || 0);
			} else {
				Ember.$("#web-app-container").css("height", Ember.$(".content-wrapper")[0].scrollHeight);
				Ember.$("#app-body").scrollTop(this.get('lastScroll') || 0);
			}
		},


		actions: {
			willTransition: function willTransition() {
				this._super.apply(this, arguments);
				var isDesktop = window.matchMedia("only screen and (min-width: 414px)").matches;
				var target = isDesktop ? "#app-body" : "BODY";
				this.set('controller.lastScroll', Ember.$(target).scrollTop());
				return true;
			}
		}
	});
});