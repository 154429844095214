define('otcns-career-pathways/pods/favourites/route', ['exports', 'otcns-career-pathways/mixins/reset-scroll'], function (exports, _resetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_resetScroll.default, {
		model: function model() {
			var self = this;
			return this.get("store").findAll('favourite').then(function (results) {
				var favs = results.map(function (item) {
					return item.get("doc_id");
				});
				return self.db.allDocs({ keys: favs, include_docs: true }).then(function (docs) {
					return docs.rows.map(function (doc) {
						return doc.doc;
					});
				});
			});
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			var alreadyInFavourites = this.controllerFor('application').get('inFavourites');

			if (!alreadyInFavourites) {
				// save the previous route before favourites were entered
				this.controllerFor('application').set('returnTo', this.controllerFor('application').get('lastRoute'));
				this.controllerFor('application').set("inFavourites", true);
			}
		},


		actions: {
			setActiveTab: function setActiveTab(which) {
				console.log(this.get("controller.model"));
				this.set("controller.activeTab", which);
			}
		}
	});
});