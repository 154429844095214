define('otcns-career-pathways/pods/components/employer-list/component', ['exports', 'otcns-career-pathways/mixins/remember-scroll'], function (exports, _rememberScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_rememberScroll.default, {

		actions: {
			selectItem: function selectItem(which) {
				this.get("onSelect")(which);
			},
			selectSorter: function selectSorter(which) {
				this.get("onSort")(which);
			}
		}
	});
});