define("otcns-career-pathways/pods/application/controller", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		notification: Ember.inject.service(),
		navigator: Ember.inject.service(),
		currentRoute: Ember.computed("navigator.currentRouteName", function () {
			return this.get("navigator.currentRouteName").split(".").join("-");
		}),
		inFavourites: false,
		transitionHistory: [],

		connectionStatus: Ember.inject.service(),

		orientation: null,

		updateOrientation: function updateOrientation() {
			var orientation = 'portrait';

			if (Math.floor(Math.abs(window.orientation) / 90) === 1) {
				orientation = 'landscape';
			}
			this.set("orientation", orientation);
		},
		init: function init() {
			this._super.apply(this, arguments);
			var connection = this.get('connectionStatus');
			//pass this instance to connectionStatus service. 
			//allows for action hooks to fire here. 
			connection.setup(this);

			window.addEventListener("orientationchange", Ember.run.bind(this, this.updateOrientation));
			this.updateOrientation();
		},


		actions: {
			online: function online(event) {
				console.log("went online", event);
				//this.notifications.success(event.type, {
				//    autoClear: true,
				//    clearDuration: 1000
				//});
			},
			offline: function offline(event) {
				console.log("went offline", event);
				//this.notifications.error(event.type, {
				//    autoClear: true,
				//    clearDuration: 1000
				//});
			}
		}

	});
});