define("otcns-career-pathways/helpers/check-expiry", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.checkExpiry = checkExpiry;

	function _toArray(arr) {
		return Array.isArray(arr) ? arr : Array.from(arr);
	}

	function checkExpiry(_ref) {
		var _ref2 = _toArray(_ref),
		    value = _ref2[0],
		    rest = _ref2.slice(1);

		var ending = moment(value);
		var now = moment();
		return ending.isSameOrAfter(now, "day");
	}

	exports.default = Ember.Helper.helper(checkExpiry);
});