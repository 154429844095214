define('otcns-career-pathways/services/connection-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    online: true,
    setup: function setup(owner) {
      var _this = this;

      ;['online', 'offline'].forEach(function (status) {
        window.addEventListener(status, function (event) {
          _this.set('online', status === 'online');
          try {
            owner.send(status, event);
          } catch (e) {}
        }, false);
      });
    }
  });
});