define('otcns-career-pathways/pods/news/index/route', ['exports', 'otcns-career-pathways/mixins/remember-scroll'], function (exports, _rememberScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_rememberScroll.default, {

		notification: Ember.inject.service(),

		model: function model() {

			var self = this;

			return this.store.findAll("subscription").then(function (results) {

				var employers = {};

				return self.db.allDocs({ include_docs: true, keys: results.map(function (item) {
						return item.get("career_id");
					}) }).then(function (resolved_careers) {

					resolved_careers.rows.forEach(function (item) {
						item.doc.employers.forEach(function (employer) {
							employers[employer] = true;
						});
					});
					return Object.keys(employers);
				});
			});
		},
		setupController: function setupController(controller, model) {

			this._super.apply(this, arguments);
			var self = this;

			this.get('notification').getData('news');

			this.get("store").findAll('favourite').then(function (results) {
				var favs = results.map(function (item) {
					return item.get("doc_id");
				});
				self.db.allDocs({ keys: favs, include_docs: true }).then(function (fav_docs) {
					controller.set("favourites", fav_docs.rows.filter(function (item) {
						return item.doc.type == 'news' || item.doc.type == 'event';
					}).map(function (item) {
						return item.doc._id;
					}));
				});
			});
		},


		actions: {
			willTransition: function willTransition(transition) {
				this.get('notification').resetNotification('news');
				return true;
			}
		}

	});
});