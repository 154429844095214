define("otcns-career-pathways/transitions/reveal-down", ["exports", "otcns-career-pathways/transitions/reveal"], function (exports, _reveal) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (opts) {
    return _reveal.default.call(this, 'y', 1, opts);
  };
});