define('otcns-career-pathways/pods/components/job-profile/component', ['exports', 'otcns-career-pathways/mixins/remember-scroll', 'otcns-career-pathways/metadata'], function (exports, _rememberScroll, _metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_rememberScroll.default, {
		active: "overview",
		favourited: false,
		region_lookup: _metadata.default.regions,

		hasLinkedEmployers: Ember.computed("model", function () {
			var bool = false;
			this.get('model.tagged_full').forEach(function (item) {
				if (item.type == 'company') bool = true;
			});
			return bool;
		}),

		hasLinkedPathways: Ember.computed("model", function () {
			var bool = false;
			this.get('model.tagged_full').forEach(function (item) {
				if (item.type == 'ocean_career_profile') bool = true;
			});
			return bool;
		}),

		actions: {
			setTab: function setTab(which) {
				this.set("active", which);
				var target = window.matchMedia("only screen and (min-width: 414px)").matches ? "#app-body" : "BODY";
				Ember.$(target).scrollTop(Ember.$(".columns.three-col").offset().top - 75);
			},
			toggleFavourite: function toggleFavourite(id) {
				this.toggleProperty("isFavourited");
				this.sendAction('toggleFavourite', id);
			},
			subscribe: function subscribe(id) {
				this.toggleProperty("isSubscribed");
				this.sendAction('subscribe', id);
			}
		}
	});
});