define('otcns-career-pathways/adapters/application', ['exports', 'ember-pouch', 'pouchdb', 'otcns-career-pathways/config/environment'], function (exports, _emberPouch, _pouchdb, _environment) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		var assert = Ember.assert,
		    isEmpty = Ember.isEmpty;


		function createDb() {
				console.log('creating db');
				var localDb = _environment.default.emberPouch.localDb;
				assert('emberPouch.localDb must be set', !isEmpty(localDb));

				var db;

				if ('cordova' in window) {
						//PouchDB.plugin(cordovaSQLite);
						db = new _pouchdb.default(localDb);
				} else {
						//PouchDB.plugin(webSQL);
						db = new _pouchdb.default(localDb);
				}

				if (_environment.default.emberPouch.remoteDb) {
						console.debug('attempting to set up replication of user data', _environment.default.emberPouch.remoteDb);

						// one-directional replication, not sync.
						_pouchdb.default.replicate(localDb, _environment.default.emberPouch.remoteDb, {
								live: true,
								retry: true,
								fetch: function fetch(url, opts) {
										opts.headers.set('X-PouchDB', 'true');
										return _pouchdb.default.fetch(url, opts);
								}
						}).on('change', function (info) {
								console.log('user data: change', info);
						}).on('paused', function (err) {
								// replication paused (e.g. replication up to date, user went offline)
								console.log('user data: paused', err);
						}).on('active', function () {
								// replicate resumed (e.g. new changes replicating, user went back online)
								console.log('active');
						}).on('denied', function (err) {
								// a document failed to replicate (e.g. due to permissions)
								console.log('denied', err);
						}).on('complete', function (info) {
								// handle complete
								console.log('complete', info);
						}).on('error', function (err) {
								// handle error
								console.log('user data: err', err);
						});
				}

				db.info().then(function (data) {
						console.debug(data);
				});
				return db;
		}

		exports.default = _emberPouch.Adapter.extend({
				init: function init() {
						this._super.apply(this, arguments);
						this.set('db', createDb());
				}
		});
});