define('otcns-career-pathways/initializers/cordova', ['exports', 'pouchdb', 'npm:pouchdb-load'], function (exports, _pouchdb, _npmPouchdbLoad) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	//import cordovaSQLite from 'npm:pouchdb-adapter-cordova-sqlite';

	_pouchdb.default.plugin({
		loadFromString: _npmPouchdbLoad.default.load
	});

	// Defers readiness of application until plugins are ready.
	exports.default = {
		name: 'cordova',

		initialize: function initialize(application) {

			console.log('initializing');

			if (typeof cordova !== 'undefined') {

				application.deferReadiness();

				document.addEventListener('deviceready', function () {

					var db = new _pouchdb.default("otcns-static-content", {
						//adapter: 'cordova-sqlite',
						revs_limit: 1,
						fetch: function fetch(url, opts) {
							opts.headers.set('X-PouchDB', 'true');
							return _pouchdb.default.fetch(url, opts);
						}
					});

					application.register('db:main', db, { instantiate: false });
					application.inject('helper', 'db', 'db:main');
					application.inject('route', 'db', 'db:main');
					application.inject('component', 'db', 'db:main');
					application.inject('service:load', 'db', 'db:main');
					application.advanceReadiness();
				}, false);
			} else {
				// we're in a web browser

				application.deferReadiness();

				var db = new _pouchdb.default("otcns-static-content", {
					fetch: function fetch(url, opts) {
						opts.headers.set('X-PouchDB', 'true');
						return _pouchdb.default.fetch(url, opts);
					}
				});

				application.register('db:main', db, { instantiate: false });
				application.inject('helper', 'db', 'db:main');
				application.inject('route', 'db', 'db:main');
				application.inject('component', 'db', 'db:main');
				application.advanceReadiness();
			}
		}
	};
});