define("otcns-career-pathways/pods/careers/controller", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		applicationController: Ember.inject.controller("application"),

		orientation: Ember.computed.oneWay("applicationController.orientation")

	});
});