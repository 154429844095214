define("otcns-career-pathways/pods/components/secondary-navigation/component", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		floating: false,
		waypoint: null,
		height: null,

		initWaypoint: function initWaypoint() {
			var self = this;

			var isDesktop = window.matchMedia("only screen and (min-width: 414px)").matches;

			self.set("height", self.$().height());

			self.set("waypoint", new Waypoint({
				element: self.$(),
				offset: 67,
				context: isDesktop ? Ember.$("#app-body")[0] : window,
				handler: function handler(direction) {
					if (direction == "down") self.set("floating", true);else self.set("floating", false);
				}
			}));
		},

		didInsertElement: function didInsertElement() {

			Ember.run.scheduleOnce("afterRender", this, 'initWaypoint');
		},
		willDestroyElement: function willDestroyElement() {

			this.get("waypoint").destroy();
		}
	});
});