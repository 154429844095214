define("otcns-career-pathways/pods/employers/index/controller", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		notification: Ember.inject.service(),

		sort_by: "alpha",

		sorter: Ember.computed("sort_by", function () {
			if (this.get("sort_by") == "alpha") return ["name:asc"];else return ["score:desc", "name:asc"]; //rating
		}),
		sorted_items: Ember.computed.sort("employers", "sorter"),

		employers: Ember.computed("notification.all_employers", "scores", function () {
			var scores = this.get("scores");
			var employers = this.get("notification.all_employers");

			if (Object.keys(scores).length == 0) return employers;

			var result = employers.map(function (employer) {
				var career_tally = (scores[employer._id] || []).length;
				var sum_of_scores = (scores[employer._id] || []).reduce(function (a, b) {
					return a + b;
				}, 0);
				var rating = Math.floor(Math.round(sum_of_scores / (career_tally || 1) * 100) / 10) - 4;
				if (rating < 1) rating = 0;

				Ember.set(employer, "rating", rating);
				Ember.set(employer, "score", rating + sum_of_scores);
				return employer;
			});

			return result;
		}),
		scores: {},

		actions: {
			loadEmployer: function loadEmployer(which) {
				//console.log("loading employer " + which);
				this.transitionToRoute("employers.profile", which);
			},
			setSorting: function setSorting(which) {
				this.set("sort_by", which);
			}
		}

	});
});