define('otcns-career-pathways/pods/employers/profile/route', ['exports', 'otcns-career-pathways/mixins/reset-scroll'], function (exports, _resetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_resetScroll.default, {
		model: function model(params) {
			var self = this;
			this.set("id", params.id);
			return this.db.get(params.id, {/*attachments: true, binary: true*/}).then(function (doc) {

				return self.db.find({
					selector: {
						$and: [{
							type: "ocean_career_profile"
						}, {
							employers: {
								$elemMatch: {
									$eq: params.id
								}
							}
						}]
					}

				}).then(function (results) {
					doc.is_employer = results.docs.length > 0;
					return doc;
				});
			});
		},
		setupController: function setupController(controller, model) {
			var self = this;
			this._super.apply(this, arguments);

			self.get("store").query('favourite', { filter: { doc_id: self.get("id") } }).then(function (results) {
				if (results.get("length") > 0) {
					console.log("favourited!");
					controller.set("isFavourited", true);
				} else {
					console.log("not favourited");
					controller.set("isFavourited", false);
				}
			});
		}
	});
});