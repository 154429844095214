define("otcns-career-pathways/pods/components/news-item/component", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		hidden: false,
		waypoint: null,
		height: null,

		initWaypoint: function initWaypoint() {
			var self = this;
			var height = self.$().height();

			var isDesktop = window.matchMedia("only screen and (min-width: 414px)").matches;

			self.set("height", height);

			self.set("waypoint", new Waypoint({
				element: self.$(),
				offset: height * -1,
				context: isDesktop ? Ember.$("#app-body")[0] : window,
				handler: function handler(direction) {
					if (direction == "down") self.set("hidden", true);else self.set("hidden", false);
				}
			}));
		},

		didRender: function didRender() {
			this.$(".news-description").dotdotdot({
				height: 60,
				tolerance: 20
			});
		},
		didInsertElement: function didInsertElement() {

			Ember.run.scheduleOnce("afterRender", this, 'initWaypoint');
		},
		willDestroyElement: function willDestroyElement() {

			this.get("waypoint").destroy();
		},


		actions: {
			click: function click(id) {
				this.sendAction("action", id);
			}
		}

	});
});