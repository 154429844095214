define('otcns-career-pathways/pods/favourites/controller', ['exports', 'otcns-career-pathways/metadata'], function (exports, _metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		region_lookup: _metadata.default.regions,

		activeTab: "careers",

		careers: Ember.computed.filter("model", function (item) {
			return item.type == "ocean_career_profile";
		}),

		employers: Ember.computed.filter("model", function (item) {
			return item.type == "company";
		}),

		news_items: Ember.computed.filter("model", function (item) {
			return item.type == 'news';
		}),

		jobs: Ember.computed.filter("model", function (item) {
			return item.type == 'job_posting';
		}),

		actions: {
			selectNewsItem: function selectNewsItem(which) {
				this.transitionToRoute("news.profile", which);
			}
		}

	});
});