define('otcns-career-pathways/pods/careers/index/route', ['exports', 'otcns-career-pathways/mixins/remember-scroll'], function (exports, _rememberScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_rememberScroll.default, {

		notification: Ember.inject.service(),

		setupController: function setupController(controller, model) {
			var self = this;
			this._super.apply(this, arguments);

			this.get('notification').getData('careers');

			// get a list of all favourited careers, set this as the controller property 'favourites'

			this.get("store").findAll('favourite').then(function (results) {
				var favs = results.map(function (item) {
					return item.get("doc_id");
				});
				self.db.allDocs({ keys: favs, include_docs: true }).then(function (fav_docs) {
					controller.set("favourites", fav_docs.rows.filter(function (item) {
						return item.doc.type == 'ocean_career_profile';
					}).map(function (item) {
						return item.doc._id;
					}));
				});
			});

			/*
   		// get a list of all subscribed pathways, then resolve those IDs to employers
   
   		this.get("store").findAll('subscription').then(function(results){
   			var subscriptions = results.map(function(item){
   				 return item.get("doc_id")
   			});
   			self.db.allDocs({keys: subscriptions, include_docs: true}).then(function(subscribed_companies){
   				controller.set("subscriptions", fav_docs.rows.filter(function(item){
   					return (item.doc.type == 'company');
   				}).map(function(item){
   					return item.doc._id;
   				}));
   			})
   
   		})
   */
		},


		actions: {
			willTransition: function willTransition(transition) {
				this.get('notification').resetNotification('careers');
				return true;
			}
		}
	});
});