define('otcns-career-pathways/pods/jobs/index/route', ['exports', 'npm:compute-cosine-similarity'], function (exports, _npmComputeCosineSimilarity) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		notification: Ember.inject.service(),

		setupController: function setupController(controller, model) {
			var self = this;
			this._super.apply(this, arguments);

			this.get('notification').getData('jobs').then(function (jobs) {
				self.get("store").findAll('profile', { reload: true }).then(function (results) {

					if (results.get("length") == 0) return;

					// a profile exists, so it should be set on the controller
					var user_vector = results.sortBy("updated").get("lastObject.vector");

					var promiseArray = [];

					var scores = [];

					jobs.forEach(function (doc) {
						if (!doc.tagged_career_pathways) return;
						doc.tagged_career_pathways.forEach(function (career_id) {
							var promise = new Ember.RSVP.Promise(function (resolve, reject) {
								self.db.get(career_id).then(function (career) {
									var profile_vector_selective = career["profile_vector_indices"].map(function (index) {
										return career["profile_vector"][index];
									});
									var user_vector_selective = career["profile_vector_indices"].map(function (index) {
										return user_vector[index];
									});
									var score = (0, _npmComputeCosineSimilarity.default)(user_vector_selective, profile_vector_selective);
									for (var i = 0; i < doc.tagged_companies.length; i++) {
										if (doc.tagged_companies[i] in career.employers) {
											score = score * 2;
											break;
										}
									}
									scores.push({ id: doc._id, score: score });
									resolve();
								});
							});
							promiseArray.push(promise);
						});
					});
					Ember.RSVP.Promise.all(promiseArray).then(function () {
						var jobs = {};
						scores.forEach(function (job) {
							if (!(job.id in jobs)) jobs[job.id] = [job.score];else jobs[job.id].push(job.score);
						});
						controller.set("scores", jobs);
					});
				});
			});

			// get a list of all favourited jobs, set this as the controller property 'favourites'

			this.get("store").findAll('favourite').then(function (results) {
				var favs = results.map(function (item) {
					return item.get("doc_id");
				});
				self.db.allDocs({ keys: favs, include_docs: true }).then(function (fav_docs) {
					controller.set("favourites", fav_docs.rows.filter(function (item) {
						return item.doc.type == 'job_posting';
					}).map(function (item) {
						return item.doc._id;
					}));
				});
			});
		},


		actions: {
			willTransition: function willTransition(transition) {
				this.get('notification').resetNotification('jobs');
				return true;
			}
		}
	});
});