define('otcns-career-pathways/pods/news/index/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		notification: Ember.inject.service(),

		moment: Ember.inject.service(),

		mode: "all",

		activeNews: Ember.computed("notification.all_news", "mode", function () {
			if (this.get("mode") == "all") return this.get("notification.all_news");

			return this.get("news_by_subscription");
		}),

		sort_news: ["date:desc"],
		sortedNews: Ember.computed.sort("activeNews", "sort_news"),

		sort_events: ["end_date:asc"],
		sortedEvents: Ember.computed.sort("notification.all_events", "sort_events"),

		displaying: 10,
		showingAll: false,

		visibleNews: Ember.computed("sortedNews", "displaying", function () {
			return this.get("sortedNews").slice(0, this.get("displaying"));
		}),

		upcomingEvents: Ember.computed.filter("sortedEvents", function (e) {
			if (!e.end_date) return false;
			var ending = moment(e.end_date);
			var now = moment();
			return ending.isSameOrAfter(now, "day");
		}),

		//visibleNews: Ember.computed.filter("sortedNews", function(item, index){
		//	return index < this.get('displaying')
		//}),

		news_by_subscription: Ember.computed("notification.all_news", "model", function () {
			var employers = this.get("model");
			if (employers.length == 0) return [];

			var filtered_news = this.get("notification.all_news").filter(function (item) {
				var found_count = 0;
				(item["tagged_companies"] || []).forEach(function (i) {
					employers.forEach(function (j) {
						if (i == j) found_count++;
					});
				});
				return found_count;
			});
			return filtered_news;
		}),

		actions: {
			showMore: function showMore() {
				var max = this.get("sortedNews").length;
				if (this.get("displaying") < max) this.incrementProperty("displaying", 10);else this.set("showingAll", true);
			},
			setMode: function setMode(which) {
				this.set("displaying", 10);
				this.set('showingAll', false);
				this.set('mode', which);
			},
			selectItem: function selectItem(which) {
				this.transitionToRoute("news.profile", which);
			}
		}
	});
});