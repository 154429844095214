define('otcns-career-pathways/initializers/connection-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'connection-status',
    initialize: function initialize(app) {
      ;['route', 'controller'].forEach(function (type) {
        app.inject(type, 'connectionStatus', 'service:connection-status');
      });
    }
  };
});