define('otcns-career-pathways/router', ['exports', 'otcns-career-pathways/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('careers', function () {
      this.route('profile', { path: '/:id' });
    });
    this.route('news', function () {
      this.route('profile', { path: '/:id' });
    });
    this.route('employers', function () {
      this.route('profile', { path: '/:id' });
    });

    this.route('profile');
    this.route('settings');
    this.route('favourites');
    this.route('self-assessment');
    this.route('jobs', function () {
      this.route('profile', { path: '/:id' });
    });
  });

  exports.default = Router;
});