define('otcns-career-pathways/models/profile', ['exports', 'ember-pouch/model', 'ember-data'], function (exports, _model, _emberData) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		var attr = _emberData.default.attr,
		    hasMany = _emberData.default.hasMany,
		    belongsTo = _emberData.default.belongsTo;
		exports.default = _model.default.extend({
				updated: _emberData.default.attr('date', {
						defaultValue: function defaultValue() {
								return new Date();
						}
				}),
				vector: _emberData.default.attr("array", { defaultValue: function defaultValue() {
								return [];
						} }),
				age: _emberData.default.attr('number'),
				gender: _emberData.default.attr('string'),
				education: _emberData.default.attr('string'),
				is_nova_scotian: _emberData.default.attr('boolean', { defaultValue: true }),
				nova_scotia_community: _emberData.default.attr('string'),
				city: _emberData.default.attr('string'),
				country: _emberData.default.attr('string', { defaultValue: "Canada" })
		});
});