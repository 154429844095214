define('otcns-career-pathways/services/load', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		notification: Ember.inject.service('notification'),
		replication: null,

		setup: function setup() {

			var self = this;
			console.log('setup');
			var db = Ember.getOwner(this).lookup("db:main");
			var isCordova = typeof cordova !== 'undefined';
			var db_seq = null;
			var existing_seq = null;

			var promiseArray = [];

			var lookupPromise = new Ember.RSVP.Promise(function (resolve, reject) {
				var ddoc = {
					_id: '_design/notification',
					views: {
						notification: {
							map: function mapFun(doc) {
								if (doc.otcns_status) {
									if (doc.type == "news" || doc.type == "news_external" || doc.type == "event" || doc.type == "job_posting" || doc.type == "company") {
										emit(doc.type, { status: doc.otcns_status });
									}
								}
								if (doc.status) {
									if (doc.type == "ocean_career_profile") {
										emit(doc.type, { status: doc.status });
									}
								}
							}.toString()
						}
					}
					// save the design doc
				};db.put(ddoc).catch(function (err) {
					if (err.name !== 'conflict') {
						throw err;
					}
					// ignore if doc already exists
				}).then(function () {
					return db.query('notification');
				}).then(function (result) {
					// handle result
					console.log('notification query done!', result);
					var lookupObj = {
						news: {},
						employers: {},
						jobs: {},
						careers: {}
					};
					result.rows.forEach(function (doc) {
						if (doc.key == "news" || doc.key == "news_external" || doc.key == "event") {
							lookupObj.news[doc.id] = doc.value.status;
						} else if (doc.key == "company") {
							lookupObj.employers[doc.id] = doc.value.status;
						} else if (doc.key == "job_posting") {
							lookupObj.jobs[doc.id] = doc.value.status;
						} else if (doc.key == "ocean_career_profile") {
							lookupObj.careers[doc.id] = doc.value.status;
						}
					});
					self.set('notification.lookupObj', lookupObj);
					resolve(lookupObj);
				}).catch(function (err) {
					reject(err);
					console.log(err);
				});
			});

			var beginReplication = function beginReplication() {
				if (self.get('replication') !== null) {
					console.debug('Service: cancelling any outstanding replications.');
					self.get('replication').cancel();
					self.set('replication', null);
				}

				console.log('beginning replication');
				var replication = db.replicate.from("https://careers.otcns.ca/db/", {
					live: false,
					retry: true,
					fetch: function fetch(url, opts) {
						opts.headers.set('X-PouchDB', 'true');
						return PouchDB.fetch(url, opts);
					}
				}).on('change', function (info) {
					console.log("Change detected");
					var promise = new Ember.RSVP.Promise(function (resolve, reject) {
						info.docs.forEach(function (row) {
							var oldStatus = false;
							if (row.type == "news" || row.type == "news_external" || row.type == "event") {
								oldStatus = self.get('notification.lookupObj').news[row._id];
							} else if (row.type == "ocean_career_profile") {
								oldStatus = self.get('notification.lookupObj').careers[row._id];
							} else if (row.type == "company") {
								oldStatus = self.get('notification.lookupObj').employers[row._id];
							} else if (row.type == "job_posting") {
								oldStatus = self.get('notification.lookupObj').jobs[row._id];
							}
							if (row.otcns_status == "active" || row.status == "active") {
								if (row.type == "event") {
									if (!row.end_date) return;
									var ending = moment(row.end_date);
									var now = moment();
									if (ending.isBefore(now, "day")) return;
								}
								if (oldStatus) {
									if (oldStatus != "active") {
										self.get('notification').addNewNotification(row);
									}
								} else {
									self.get('notification').addNewNotification(row);
								}
							}
						});
						resolve();
					});
					promiseArray.push(promise);
				}).on('paused', function (err) {
					// replication paused (e.g. replication up to date, user went offline)
					console.log("Replication paused", err);
					if (err.name == "AbortError") {
						console.log("Replication was Aborted");
						return;
					}
					Ember.RSVP.Promise.all(promiseArray).then(function () {
						promiseArray = [];
						return;
					}).then(function () {
						db.get('_local/notifications').catch(function (err) {
							if (err.name === 'not_found') {
								return { _id: '_local/notifications', news: [], employers: [], jobs: [], careers: [] };
							} else {
								// hm, some other error
								throw err;
							}
						}).then(function (configDoc) {
							configDoc = self.get('notification').syncNotifications(configDoc);
							return db.put(configDoc).then(function () {
								console.log('local notifications put successful');
							}).catch(function (err) {
								console.log(err);
							});
						}).catch(function (err) {
							console.log(err);
						});
					}).then(function () {
						db.query('notification').then(function (result) {
							// handle result
							console.log('notification query 2 done!', result);
							var lookupObj = {
								news: {},
								employers: {},
								jobs: {},
								careers: {}
							};
							result.rows.forEach(function (doc) {
								if (doc.key == "news" || doc.key == "news_external" || doc.key == "event") {
									lookupObj.news[doc.id] = doc.value.status;
								} else if (doc.key == "company") {
									lookupObj.employers[doc.id] = doc.value.status;
								} else if (doc.key == "job_posting") {
									lookupObj.jobs[doc.id] = doc.value.status;
								} else if (doc.key == "ocean_career_profile") {
									lookupObj.careers[doc.id] = doc.value.status;
								}
							});
							self.set('notification.lookupObj', lookupObj);
						});
					});
				}).on('active', function () {
					// replicate resumed (e.g. new changes replicating, user went back online)
					console.log("Replication resumed");
				}).on('denied', function (err) {
					// a document failed to replicate (e.g. due to permissions)
					console.log("Document failed to replicate", err);
				}).on('complete', function (info) {
					// handle complete
					console.log("Replication complete");
				}).on('error', function (err) {
					// handle error
					console.log("Replication error: ", err);
				});

				self.set('replication', replication);
			};

			return Ember.RSVP.allSettled([Ember.$.get("assets/career_app_sequence.txt"), db.get('_local/preloaded')]).then(function (responses) {

				console.log(responses);

				var state_hash = {
					content_exists: false
				};

				if (responses[0].state == 'rejected') db_seq = "1";else db_seq = responses[0].value;

				if (responses[1].state == 'rejected') {
					state_hash.content_exists = false;
					state_hash['pouch_response'] = responses[1].reason;
				} else {
					state_hash.content_exists = true;
					state_hash['preloaded_doc'] = responses[1].value;
				}

				return state_hash;
			}).then(function (status) {

				console.log(status, db_seq);

				return new Ember.RSVP.Promise(function (resolve, reject) {

					var preloaded_doc = { _id: '_local/preloaded' }; // this will be the local document we use to store the updated sequence on the client

					var setPreloadFlag = function setPreloadFlag() {

						db.put(preloaded_doc).then(function () {
							console.log('put successful');
							Ember.RSVP.Promise.all([db.createIndex({
								index: {
									fields: ['type']
								}
							})]).then(function () {
								lookupPromise.then(function (data) {
									beginReplication();
									resolve();
								});
							}).catch(function (err) {

								console.log(err);
								reject();
							});
						}).catch(function (err) {
							console.log('error putting');
						});
					};

					if (status.content_exists) {

						if (db_seq == status.preloaded_doc['sequence']) {
							// there are no changes to import

							console.log('up to date, handing off to replication');
							lookupPromise.then(function (data) {
								beginReplication();
								resolve();
								return;
							});
						}
					}

					// if we've reached this line, then there are changes to import.
					// either the database has never been loaded, or it's been updated


					if ('pouch_response' in status && status.pouch_response.name !== 'not_found') {

						reject();
					}

					if ('preloaded_doc' in status) {
						// this ensure we have the correct _rev key for an existing local document

						preloaded_doc = status.preloaded_doc;
					}

					preloaded_doc.sequence = db_seq;

					console.log("Loading the database now.");

					db.load("assets/career-database.txt").then(function (response) {

						db.query('notification').then(function (result) {
							// handle result
							console.log('notification query done!', result);
							var lookupObj = {
								news: {},
								employers: {},
								jobs: {},
								careers: {}
							};
							result.rows.forEach(function (doc) {
								if (doc.key == "news" || doc.key == "news_external" || doc.key == "event") {
									lookupObj.news[doc.id] = doc.value.status;
								} else if (doc.key == "company") {
									lookupObj.employers[doc.id] = doc.value.status;
								} else if (doc.key == "job_posting") {
									lookupObj.jobs[doc.id] = doc.value.status;
								} else if (doc.key == "ocean_career_profile") {
									lookupObj.careers[doc.id] = doc.value.status;
								}
							});
							self.set('notification.lookupObj', lookupObj);
							console.log('loaded');
							console.log(preloaded_doc);
							// create the local document to note that we've preloaded
							setPreloadFlag();
						});
					}).catch(function (err) {

						console.log(JSON.stringify(err));
						console.log('error', err);
						console.log('trying again');
						db.load("assets/career-database.txt").then(function () {
							console.log('setting preload after error');
							setPreloadFlag();
						});
						//reject();
					});
				});
			}).catch(function (err) {
				console.log(err);
			});
		}
	});
});