define('otcns-career-pathways/pods/careers/index/controller', ['exports', 'npm:compute-cosine-similarity'], function (exports, _npmComputeCosineSimilarity) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		notification: Ember.inject.service(),

		parentController: Ember.inject.controller("careers"),
		profile: Ember.computed.oneWay("parentController.model.profile"),

		sort_by: "alpha",

		sorter: Ember.computed("sort_by", function () {
			if (this.get("sort_by") == "alpha") return ["name:asc"];else if (this.get("sort_by") == "prospects") return ["rating:desc"];else if (this.get("sort_by") == "income") return ["income:desc"];
		}),
		sorted_items: Ember.computed.sort("pathways", "sorter"),

		// compute scores if the user has a profile, otherwise just return the model
		//  (which already has favourites identified)
		pathways: Ember.computed("notification.all_careers", "profile", function () {

			var careers = this.get("notification.all_careers");
			var user_vector = this.get("profile");

			if (!user_vector) return careers;

			// ari
			//var user_vector = [5,3,3,2,2,2,4,1,3,4,4,4,3,3,5,4,4,1,1,0,1,1,1,1,1,1];
			// jonathan
			//var user_vector = [4,5,0,1,1,0,5,4,3,5,3,3,5,4,4,4,5,1,1,1,1,1,1,1,1,0];

			var result = careers.map(function (doc) {
				if (user_vector && user_vector.length) {

					var profile_vector_selective = doc["profile_vector_indices"].map(function (index) {
						return doc["profile_vector"][index];
					});
					var user_vector_selective = doc["profile_vector_indices"].map(function (index) {
						return user_vector[index];
					});
					var score = Math.floor(Math.round((0, _npmComputeCosineSimilarity.default)(user_vector_selective, profile_vector_selective) * 100) / 10) - 4;
					if (score < 1) score = 1;
					Ember.set(doc, "score", score);
				}
				return doc;
			});
			return result;
		}),

		actions: {
			loadCareer: function loadCareer(which) {
				//console.log("loading career on portrait " + which);
				this.transitionToRoute("careers.profile", which);
			},
			setSorting: function setSorting(which) {
				this.set("sort_by", which);
			}
		}
	});
});