define('otcns-career-pathways/pods/index/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		beforeModel: function beforeModel() {
			// examine whether a self-assessment has been completed
			// if not, load the careers section
			// if so, load the news section
			this.transitionTo('careers');
		}
	});
});