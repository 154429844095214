define('otcns-career-pathways/pods/self-assessment/route', ['exports', 'otcns-career-pathways/mixins/reset-scroll'], function (exports, _resetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_resetScroll.default, {
		model: function model() {

			var DS = this.store;

			return DS.findAll('profile', { reload: true }).then(function (profiles) {
				if (profiles.get("length") == 0) return DS.createRecord("profile");
				return profiles.sortBy("updated").get("lastObject");
			});

			/*		
   		return DS.findRecord('profile', 'profile').then(
   			function(results){
   				console.log("User already has a profile. Returning it now.");
   				return results;
   
   			},
   			function(err){	// profile hasn't been initialized
   
   				console.log("Creating profile document for the first time now.");
   				var settings = DS.createRecord('profile', {id: 'profile'});
   				return settings;
   			}
   		)
   */
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set("step", 1);
			var vector = [];

			// check to see whether the user has completed the self assessment already
			// if so, load their vector and assign it to response.vector

			var count = 27;
			while (--count) {
				vector.push(0);
			};

			controller.set("response.vector", vector);
			controller.set("response.age", model.get("age"));
			controller.set("response.gender", model.get("gender"));
			controller.set("response.education", model.get("education"));
			controller.set("response.is_nova_scotian", model.get("is_nova_scotian"));
			controller.set("response.nova_scotia_community", model.get("nova_scotia_community"));
			controller.set("response.city", model.get("city"));
			controller.set("response.country", model.get("country"));
		}
	});
});