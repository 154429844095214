define('otcns-career-pathways/pods/profile/route', ['exports', 'otcns-career-pathways/mixins/reset-scroll'], function (exports, _resetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_resetScroll.default, {
		model: function model() {
			var db = this.db;

			return this.store.findAll("subscription").then(function (results) {

				return db.allDocs({ include_docs: true, keys: results.map(function (item) {
						return item.get("career_id");
					}) }).then(function (resolved_careers) {

					return resolved_careers.rows.map(function (item) {
						return item.doc;
					});
				});
			});
		}
	});
});