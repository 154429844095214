define('otcns-career-pathways/pods/components/career-profile/component', ['exports', 'npm:blob-util'], function (exports, _npmBlobUtil) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		active: "overview",
		favourited: false,

		init: function init() {
			this._super.apply(this, arguments);
			var self = this;
			var doc = this.get("model");

			if (!doc.hero_shot) {
				this.set("hero_image", false);
				return;
			}

			var name = doc.hero_shot;
			this.db.getAttachment(doc._id, name).then(function (attachment) {
				self.set("hero_image", Ember.String.htmlSafe("background-image: url('" + _npmBlobUtil.default.createObjectURL(attachment) + "');"));
			});
		},


		actions: {
			setTab: function setTab(which) {
				this.set("active", which);
				var target = window.matchMedia("only screen and (min-width: 414px)").matches ? "#app-body" : "BODY";
				Ember.$(target).scrollTop(Ember.$(".columns.three-col").offset().top - 75);
			},
			toggleFavourite: function toggleFavourite(id) {
				this.toggleProperty("isFavourited");
				this.sendAction('toggleFavourite', id);
			},
			subscribe: function subscribe(id) {
				this.toggleProperty("isSubscribed");
				this.sendAction('subscribe', id);
			}
		}
	});
});