define("otcns-career-pathways/pods/components/header-bar/component", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		actions: {
			goBack: function goBack() {
				this.sendAction("back");
			},
			dismiss: function dismiss() {
				this.sendAction("dismiss");
			},
			clickTab: function clickTab(which) {
				this.get("onClickTab")(which);
			}
		}
	});
});