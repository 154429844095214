define('otcns-career-pathways/pods/jobs/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {

			var self = this;

			return self.get("store").findAll('profile', { reload: true }).then(function (profiles) {
				if (profiles.get("length") == 0) return { profile: false };

				return { profile: profiles.sortBy("updated").get("lastObject.vector") };
			});
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			var self = this;
		}
	});
});