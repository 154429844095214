define('otcns-career-pathways/pods/employers/index/route', ['exports', 'otcns-career-pathways/mixins/remember-scroll', 'npm:compute-cosine-similarity'], function (exports, _rememberScroll, _npmComputeCosineSimilarity) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_rememberScroll.default, {

		notification: Ember.inject.service(),

		setupController: function setupController(controller, model) {
			var self = this;
			this._super.apply(this, arguments);

			this.get('notification').getData('employers');

			// retrieve the user's vector if it exists. get a list of career profile rankings.

			this.get("store").findAll('profile', { reload: true }).then(function (results) {

				if (results.get("length") == 0) return;

				// a profile exists, so it should be set on the controller
				var user_vector = results.sortBy("updated").get("lastObject.vector");

				self.db.find({ selector: { type: "ocean_career_profile" } }).then(function (careers) {

					return careers.docs.map(function (doc) {
						var profile_vector_selective = doc["profile_vector_indices"].map(function (index) {
							return doc["profile_vector"][index];
						});
						var user_vector_selective = doc["profile_vector_indices"].map(function (index) {
							return user_vector[index];
						});
						var score = (0, _npmComputeCosineSimilarity.default)(user_vector_selective, profile_vector_selective);
						doc["employers_scored"] = doc["employers"].map(function (employer) {
							return { id: employer, score: score };
						});
						return doc;
					});
				}).then(function (scored_careers) {
					var employers = {};
					scored_careers.forEach(function (career) {
						career.employers_scored.forEach(function (employer) {
							if (!(employer.id in employers)) employers[employer.id] = [employer.score];else employers[employer.id].push(employer.score);
						});
					});
					controller.set("scores", employers);
				});
			});

			this.get("store").findAll('favourite').then(function (results) {
				var favs = results.map(function (item) {
					return item.get("doc_id");
				});
				self.db.allDocs({ keys: favs, include_docs: true }).then(function (fav_docs) {
					controller.set("favourites", fav_docs.rows.filter(function (item) {
						return item.doc.type == 'company';
					}).map(function (item) {
						return item.doc._id;
					}));
				});
			});
		},


		actions: {
			willTransition: function willTransition(transition) {
				this.get('notification').resetNotification('employers');
				return true;
			}
		}
	});
});