define('otcns-career-pathways/components/add-to-homescreen', ['exports', 'ember-add-to-homescreen/components/add-to-homescreen'], function (exports, _addToHomescreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _addToHomescreen.default;
    }
  });
});