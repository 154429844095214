define('otcns-career-pathways/pods/components/employer-profile/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		favourited: false,

		actions: {
			toggleFavourite: function toggleFavourite(id) {
				this.toggleProperty("isFavourited");
				this.sendAction('toggleFavourite', id);
			}
		}

	});
});