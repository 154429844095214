define('otcns-career-pathways/pods/jobs/index/controller', ['exports', 'otcns-career-pathways/metadata'], function (exports, _metadata) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		notification: Ember.inject.service(),

		region_lookup: _metadata.default.regions,

		sort_by: "alpha",

		sorter: Ember.computed("sort_by", function () {
			if (this.get("sort_by") == "alpha") return ["title:asc"];else if (this.get("sort_by") == "deadline") return ["deadline:asc"];else if (this.get("sort_by") == "score") return ["score:desc"];
		}),
		sorted_items: Ember.computed.sort("upcomingJobs", "sorter"),

		upcomingJobs: Ember.computed("notification.all_jobs", "scores", function (item) {

			var scores = this.get("scores");
			var jobs = this.get("notification.all_jobs").filter(function (jobfilter) {
				if (!jobfilter.deadline) return false;
				var ending = moment(jobfilter.deadline);
				var now = moment();
				if (ending.isBefore(now, "day")) {
					return false;
				} else {
					return true;
				}
			});

			if (Object.keys(scores).length == 0) return jobs;

			var result = jobs.map(function (job) {

				var career_tally = (scores[job._id] || []).length;
				var sum_of_scores = (scores[job._id] || []).reduce(function (a, b) {
					return a + b;
				}, 0);
				var rating = Math.floor(Math.round(sum_of_scores / (career_tally || 1) * 100) / 10) - 4;
				if (rating < 1) rating = 0;

				Ember.set(job, "score", rating + sum_of_scores);
				return job;
			});
			return result;
		}),
		scores: {},

		actions: {
			selectItem: function selectItem(which) {
				this.transitionToRoute("jobs.profile", which);
			},
			setSorting: function setSorting(which) {
				this.set("sort_by", which);
			}
		}

	});
});