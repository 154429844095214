define('otcns-career-pathways/transitions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {

		this.transition(this.fromRoute(['employers', 'careers', 'news']), this.toRoute(['employers', 'careers', 'news']), this.use('crossFade', { duration: 300 }), this.reverse('crossFade', { duration: 300 })
		//		this.debug()
		//		this.reverse('toRight')
		);

		/*
  	this.transition(
  		this.fromRoute(/\*\.index/),
  		this.toRoute(/\*\.index/),
  		this.use("crossFade", {duration: 3000 }),
  		this.debug()
  	);
  */

		this.transition(this.fromRoute(['news', 'news.index']), this.toRoute('news.profile'), this.use("toLeft", { duration: 300, easing: "easeOutQuart" }), this.reverse("toRight", { duration: 300, easing: "easeOutQuart" }));

		this.transition(this.fromRoute(['employers', 'employers.index']), this.toRoute('employers.profile'), this.use("toLeft", { duration: 300, easing: "easeOutQuart" }), this.reverse("toRight", { duration: 300, easing: "easeOutQuart" }));

		this.transition(this.fromRoute(['careers', 'careers.index']), this.toRoute('careers.profile'), this.use("toLeft", { duration: 300, easing: "easeOutQuart" }), this.reverse("toRight", { duration: 300, easing: "easeOutQuart" }));

		this.transition(this.toRoute("favourites"), this.use("toUp", { duration: 300, easing: "easeOutQuart" }), this.reverse("toDown", { duration: 300 }));

		this.transition(this.toRoute("self-assessment"), this.use("toDown", { duration: 300, easing: "easeOutQuart" }), this.reverse("toUp", { duration: 300 }));

		// self-assessment steps 1-2
		this.transition(this.toValue(function (toValue, fromValue) {
			if (toValue.type == "assessment") {
				if (fromValue.step < toValue.step) {
					if (toValue.step < 4) return true;
				}
			}
		}), this.use("toLeft", { easing: "spring", duration: 600 }), this.reverse("toRight", { easing: "spring", duration: 600 }));

		// self-assessment steps 3-17
		this.transition(this.toValue(function (toValue, fromValue) {
			if (toValue.type == "assessment") {
				if (fromValue.step < toValue.step) {
					if (toValue.step > 3 && toValue.step < 21) return true;
				}
			}
		}), this.use("explode", {
			pickOld: "STRONG.question",
			use: ["toLeft", { easing: "spring", duration: 1000 }]
		}, {
			pickNew: "STRONG.question",
			use: ["toLeft", { easing: "spring", duration: 600 }]
		}, {
			matchBy: "data-choice-id",
			use: ["fly-to", { easing: "spring", duration: 600 }]
		}), this.reverse("explode", {
			pickOld: "STRONG.question",
			use: ["toRight", { easing: "spring", duration: 1000 }]
		}, {
			pickNew: "STRONG.question",
			use: ["toRight", { easing: "spring", duration: 600 }]
		}, {
			matchBy: "data-choice-id",
			use: ["fly-to", { easing: "spring", duration: 600 }]
		})
		//this.debug()
		);
	};

	;
});