define('otcns-career-pathways/pods/components/pouchdb-image/component', ['exports', 'npm:blob-util'], function (exports, _npmBlobUtil) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		tagName: "img",

		didInsertElement: function didInsertElement() {

			var component = this;

			this.db.getAttachment(this.get("_id"), this.get("filename")).then(function (attachment) {
				component.$().attr("src", _npmBlobUtil.default.createObjectURL(attachment));
			});
		},
		willDestroyElement: function willDestroyElement() {
			_npmBlobUtil.default.revokeObjectURL(this.$().attr("src"));
		}
	});
});