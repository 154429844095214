define('otcns-career-pathways/pods/components/news-list/component', ['exports', 'otcns-career-pathways/mixins/remember-scroll'], function (exports, _rememberScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_rememberScroll.default, {

		/*
  	events: Ember.computed.filter("model.events", function(item){
  		return 
  	})
  */
		actions: {
			clickMore: function clickMore() {
				this.get('onClickMore')();
			},
			clickTab: function clickTab(which) {
				this.get('onClickTab')(which);
			},
			selectItem: function selectItem(which) {
				this.get("onSelect")(which);
			}
		}
	});
});