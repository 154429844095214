define('otcns-career-pathways/pods/careers/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {

			var self = this;

			return self.get("store").findAll('profile', { reload: true }).then(function (profiles) {
				if (profiles.get("length") == 0) return { profile: false };

				return { profile: profiles.sortBy("updated").get("lastObject.vector") };
			});
		},
		setupController: function setupController(controller, model) {

			this._super.apply(this, arguments);

			// retrieve the user's vector if it exists
			// this ensures that it's always updated each time careers is revisited
			// the model hook ensures it's loaded the first time before rendering

			this.get("store").findAll('profile', { reload: true }).then(function (profiles) {

				if (profiles.get("length") == 0) {
					controller.set("model.profile", false);
				} else {
					controller.set("model.profile", profiles.sortBy("updated").get("lastObject.vector"));
				}
			});
		},


		actions: {
			loading: function loading() {
				console.log("careers loading action");
				return true;
			},
			subscribe: function subscribe(id) {

				var self = this;
				this.get("store").query('subscription', { filter: { career_id: id } }).then(function (results) {

					if (results.get("length") > 0) {
						// no subscriptions found. erase all.

						results.forEach(function (item) {
							item.destroyRecord();
						});
					} else {

						var subscription = self.store.createRecord('subscription', {
							career_id: id
						});

						subscription.save().then(function (newDoc) {
							console.log("created subscription", newDoc);
						});
					}
				}).catch(function (err) {
					console.log(err);
				});
			}
		}

	});
});