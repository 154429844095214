define('otcns-career-pathways/transitions/reveal', ['exports', 'liquid-fire'], function (exports, _liquidFire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = reveal;
  function reveal(dimension, direction, opts) {
    var _this = this;

    var property = void 0,
        measure = void 0,
        firstStep = void 0;
    var oldParams = {};
    var revealingElement = findRevealingElement(this);

    if (dimension.toLowerCase() === 'x') {
      property = 'translateX';
      measure = 'width';
    } else {
      property = 'translateY';
      measure = 'height';
    }

    if (revealingElement) {
      // We still have some older version that is in the process of
      // revealing, so our first step is waiting for it to finish.
      firstStep = (0, _liquidFire.finish)(revealingElement, 'revealing-out');
    } else {
      firstStep = _liquidFire.Promise.resolve();
    }

    return firstStep.then(function () {
      var bigger = biggestSize(_this, measure);
      oldParams[property] = bigger * direction + 'px';

      _this.oldElement.css({ visibility: '', zIndex: 1 });
      _this.newElement.css({ visibility: '', zIndex: 0 });
      return (0, _liquidFire.animate)(_this.oldElement, oldParams, opts, 'revealing-out');
    });
  }

  function biggestSize(context, dimension) {
    var sizes = [];
    if (context.newElement) {
      sizes.push(parseInt(context.newElement.css(dimension), 10));
      sizes.push(parseInt(context.newElement.parent().css(dimension), 10));
    }
    if (context.oldElement) {
      sizes.push(parseInt(context.oldElement.css(dimension), 10));
      sizes.push(parseInt(context.oldElement.parent().css(dimension), 10));
    }
    return Math.max.apply(null, sizes);
  }

  function findRevealingElement(context) {
    for (var i = 0; i < context.older.length; i++) {
      var entry = context.older[i];
      if ((0, _liquidFire.isAnimating)(entry.element, 'revealing-out')) {
        return entry.element;
      }
    }
    if ((0, _liquidFire.isAnimating)(context.oldElement, 'revealing-out')) {
      return context.oldElement;
    }
  }
});