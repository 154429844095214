define("otcns-career-pathways/mixins/reset-scroll", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		activate: function activate() {
			this._super.apply(this, arguments);
			var isDesktop = window.matchMedia("only screen and (min-width: 414px)").matches;
			if (!isDesktop) {
				Ember.$("BODY").scrollTop(0);
				Ember.$("BODY").css("minHeight", "");
			} else {
				Ember.$("#app-body").scrollTop(0);
				Ember.$("#web-app-container").css("height", "");
			}
		}
	});
});