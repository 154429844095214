define('otcns-career-pathways/pods/careers/profile/route', ['exports', 'otcns-career-pathways/mixins/reset-scroll'], function (exports, _resetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_resetScroll.default, {
		model: function model(params) {
			var self = this;
			this.set("id", params.id);
			return this.db.get(params.id).then(function (doc) {

				var employers = doc.employers;
				return self.db.allDocs({ keys: doc.employers, include_docs: true }).then(function (results) {
					doc.employers_full = results.rows.map(function (item) {
						return item.doc;
					});
					return doc;
				});
			});
		},
		setupController: function setupController(controller, model) {
			var self = this;
			this._super.apply(this, arguments);

			self.get("store").query('favourite', { filter: { doc_id: self.get("id") } }).then(function (results) {
				console.log("favourites", results);
				if (results.get("length") > 0) {
					controller.set("isFavourited", true);
				} else {
					controller.set("isFavourited", false);
				}
			});

			self.get("store").query('subscription', { filter: { career_id: self.get("id") } }).then(function (results) {
				console.log("subscriptions", results);
				if (results.get("length") > 0) {
					controller.set("isSubscribed", true);
				} else {
					controller.set("isSubscribed", false);
				}
			});
		}
	}

	//	setupController(controller){
	//		controller.set("category", this.get("category"));

	);
});