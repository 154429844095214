define('otcns-career-pathways/pods/jobs/profile/route', ['exports', 'otcns-career-pathways/mixins/reset-scroll'], function (exports, _resetScroll) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_resetScroll.default, {
		model: function model(params) {
			var self = this;
			this.set("id", params.id);
			return this.db.get(params.id).then(function (doc) {
				var docKeys = doc.tagged_companies;
				if (doc.tagged_career_pathways) {
					docKeys = doc.tagged_companies.concat(doc.tagged_career_pathways);
				}
				return self.db.allDocs({ keys: docKeys, include_docs: true }).then(function (results) {
					doc.tagged_full = results.rows.map(function (item) {
						return item.doc;
					});
					return doc;
				});
			});
		},
		setupController: function setupController(controller, model) {
			var self = this;
			this._super.apply(this, arguments);

			self.get("store").query('favourite', { filter: { doc_id: self.get("id") } }).then(function (results) {
				if (results.get("length") > 0) {
					console.log("favourited!");
					controller.set("isFavourited", true);
				} else {
					console.log("not favourited");
					controller.set("isFavourited", false);
				}
			});
		}
	});
});