define("otcns-career-pathways/services/notification", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		all_news: [],

		all_events: [],

		all_employers: [],

		all_careers: [],

		all_jobs: [],

		new_news: [],

		new_careers: [],

		new_jobs: [],

		new_employers: [],

		lookupObj: { news: {}, employers: {}, jobs: {}, careers: {} },

		addNewNotification: function addNewNotification(doc) {
			if (doc.type == "news" || doc.type == "news_external" || doc.type == "event") {
				this.get('new_news').pushObject(doc._id);
			} else if (doc.type == "company") {
				this.get('new_employers').pushObject(doc._id);
			} else if (doc.type == "job_posting") {
				this.get('new_jobs').pushObject(doc._id);
			} else if (doc.type == "ocean_career_profile") {
				this.get('new_careers').pushObject(doc._id);
			}
		},

		syncNotifications: function syncNotifications(oldNotifications) {
			var self = this;

			var syncedNotifications = { _id: '_local/notifications', _rev: oldNotifications._rev, news: [], employers: [], jobs: [], careers: [] };

			oldNotifications.news.forEach(function (value) {
				if (self.get('new_news').indexOf(value) === -1) {
					self.get('new_news').pushObject(value);
				}
			});
			oldNotifications.employers.forEach(function (value) {
				if (self.get('new_employers').indexOf(value) === -1) {
					self.get('new_employers').pushObject(value);
				}
			});
			oldNotifications.jobs.forEach(function (value) {
				if (self.get('new_jobs').indexOf(value) === -1) {
					self.get('new_jobs').pushObject(value);
				}
			});
			oldNotifications.careers.forEach(function (value) {
				if (self.get('new_careers').indexOf(value) === -1) {
					self.get('new_careers').pushObject(value);
				}
			});
			syncedNotifications.news = self.get('new_news');
			syncedNotifications.employers = self.get('new_employers');
			syncedNotifications.jobs = self.get('new_jobs');
			syncedNotifications.careers = self.get('new_careers');

			return syncedNotifications;
		},

		resetNotification: function resetNotification(type) {
			var db = Ember.getOwner(this).lookup("db:main");
			var isCordova = typeof cordova !== 'undefined';
			var self = this;

			if (type == "news") {
				this.set('new_news', []);
			} else if (type == "careers") {
				this.set('new_careers', []);
			} else if (type == "jobs") {
				this.set('new_jobs', []);
			} else if (type == "employers") {
				this.set('new_employers', []);
			}

			db.get('_local/notifications').catch(function (err) {
				if (err.name === 'not_found') {
					return { _id: '_local/notifications', news: [], employers: [], jobs: [], careers: [] };
				} else {
					// hm, some other error
					throw err;
				}
			}).then(function (configDoc) {
				configDoc.news = self.get('new_news');
				configDoc.jobs = self.get('new_jobs');
				configDoc.employers = self.get('new_employers');
				configDoc.careers = self.get('new_careers');
				return db.put(configDoc).then(function () {
					console.log('local notifications put successful');
				}).catch(function (err) {
					console.log(err);
				});
			}).catch(function (err) {
				console.log(err);
			});
		},

		init: function init() {
			this._super.apply(this, arguments);
			var self = this;
			Ember.RSVP.all([this.getData('employers'), this.getData('news'), this.getData('careers'), this.getData('jobs')]).then(function (result) {});
		},
		getData: function getData(which) {
			var db = Ember.getOwner(this).lookup("db:main");
			var isCordova = typeof cordova !== 'undefined';
			var self = this;

			if (which == 'news') {
				if (this.get('all_news.length') === 0 || this.get('all_events.length') === 0 || this.get('new_events.length') > 0 || this.get('new_news.length') > 0) {
					return Ember.RSVP.all([db.find({
						selector: {
							type: { $eq: "news" },
							date: { $exists: true },
							otcns_status: { $eq: "active" }
						}
					}), db.find({
						selector: {
							type: { $eq: "event" }
						}
					})]).then(function (result) {
						self.set('all_news', result[0].docs);
						self.set('all_events', result[1].docs);
						return {
							news: result[0].docs,
							events: result[1].docs
						};
					}).catch(function (err) {
						console.log(err);
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve([self.get('all_news'), self.get('all_events')]);
					});
				}
			}

			if (which == 'employers') {
				if (this.get('all_employers').length === 0 || this.get('new_employers.length') > 0) {
					return db.find({

						selector: { type: "company", otcns_status: "active", display_in_otcns_career_app: true }

					}).then(function (result) {
						self.set('all_employers', result.docs);
						return result.docs;
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve(self.get('all_employers'));
					});
				}
			}

			if (which == 'careers') {
				if (this.get('all_careers').length === 0 || this.get('new_careers.length') > 0) {

					return db.find({

						selector: { type: "ocean_career_profile" }

					}).then(function (result) {

						var prospects_lookup = { good: 5, fair: 3, limited: 1 };

						var docs = result.docs.map(function (doc) {
							doc["rating"] = prospects_lookup[doc.prospects] + doc.employers.length;
							return doc;
						});

						self.set('all_careers', result.docs);
						return docs;
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve(self.get('all_careers'));
					});
				}
			}

			if (which == 'jobs') {
				if (this.get('all_jobs').length === 0 || this.get('new_jobs.length') > 0) {
					return db.find({

						selector: { type: { $eq: "job_posting" } }

					}).then(function (result) {
						var promises = result.docs.map(function (job) {
							return db.allDocs({ keys: job.tagged_companies, include_docs: true }).then(function (results) {
								job.tagged_companies_full = results.rows.map(function (item) {
									return item.doc;
								});
								return job;
							});
						});
						return Promise.all(promises).then(function (results) {
							self.set('all_jobs', result.docs);
							return results;
						});
					});
				} else {
					return new Ember.RSVP.Promise(function (resolve, reject) {
						resolve(self.get('all_jobs'));
					});
				}
			}
		}
	});
});