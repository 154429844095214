define('otcns-career-pathways/helpers/pouchdb-blob-url', ['exports', 'npm:blob-util'], function (exports, _npmBlobUtil) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		compute: function compute(params, namedArgs) {

			return this.db.getAttachment(namedArgs.id, namedArgs.filename).then(function (attachment) {
				return _npmBlobUtil.default.createObjectURL(attachment);
			});
		}
	});
});